import { AlertTypes } from "../util/Constant.js";
import i18n from "../localization/i18n";
import ApiEngine from "../util/ApiEngine.js";
import { ApiUrl, ApiKey } from "../util/Constant";
import { numberWithCurrencyFormat } from "../util/Util";

export const _BIND_MEMBER = "bindMember";
export const _BIND_GAMES = "bindGames";
export const _SET_UNCLAIM_COMMISSION = "setUnclaimComm";
export const _SET_UNCLAIM_REFERRAL_BONUS = "setUnclaimReferralBonus";

export const _BUSY = "busy";
export const _IDLE = "idle";
export const _API_CALLED = "setApiCalled";
export const _DOWNLINE_SEARCH = "setDownlineSearchTextCalled";
export const _DOWNLINE_QUERY = "setDownlineQueryCalled";
export const _REFERRAL_SEARCH = "setReferralSearchTextCalled";
export const _REFERRAL_QUERY = "setReferralQueryCalled";
export const _GAME_SEARCH = "setGameSearchTextCalled";
export const _GAME_QUERY = "setGameQueryCalled";
export const _SET_Y = "setY";
export const _SET_PAGESIZE = "setPageSize";
export const _SET_ACTIVE_TAB = "setActiveTab";
export const _IS_KEEPED_LOGIN = "userKeepLogin";
export const _SET_BOTTOM_TAB_INDEX = "setBottomTabIndex";
export const _SET_PAYMENT_LIST = "setPaymentList";
export const _SET_WALLET_BALANCE = "setWalletBalance";
export const _SET_WALLET_INFO = "setWalletInfo";
export const _SET_OPEN_MENU = "setOpenMenu";
export const _SET_REPORT_OVERVIEW = "setReportOverview";
export const _SET_DOWNLINE = "setDownline";
export const _SET_BANNERS = "setBanners";
export const _SET_THEME_NAME = "setThemeName";
export const _SET_DEPOSIT_MODAL = "setDepositModal";
export const _SET_DEPOSIT_TAB = "setDepositTab";
export const _SET_LOGIN_MODAL = "setLoginModal";
export const _SET_REGISTER_MODAL = "setRegisterModal";
export const _ALERT_TYPES = {
  SET_ALERT: "SetAlert",
  NONE: "AlertNone",
};

export const setY = (y) => {
  return (dispatch) => {
    dispatch({
      type: _SET_Y,
      y,
    });
  };
};

export const setBottomTabIndex = (bottomTabIndex) => {
  return (dispatch) => {
    dispatch({
      type: _SET_BOTTOM_TAB_INDEX,
      bottomTabIndex,
    });
  };
};

export const setBannners = (banners) => {
  return (dispatch) => {
    dispatch({
      type: _SET_BANNERS,
      banners,
    });
  };
};

export const bindMember = async (dispatch) => {
  let member = [];
  let balance = 0;

  let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);
  if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
    dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
    throw responseJson[ApiKey._API_MESSAGE_KEY];
  }

  member = responseJson[ApiKey._API_DATA_KEY];
  balance = isNaN(parseFloat(member["primaryCredit"]), 3)
    ? "0.00"
    : numberWithCurrencyFormat(parseFloat(member["primaryCredit"]), 2, true);
  dispatch({
    type: _BIND_MEMBER,
    member: member,
    walletBalance: balance,
  });
};

export const setUnclaimComm = async (dispatch) => {
  let responseJson = await ApiEngine.get(
    ApiUrl._API_GET_UNCLAIM_COMMISSION_AMT
  );
  if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
    dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
    throw responseJson[ApiKey._API_MESSAGE_KEY];
  }

  let data = responseJson[ApiKey._API_DATA_KEY];

  dispatch({
    type: _SET_UNCLAIM_COMMISSION,
    data: data,
  });
};

export const setUnclaimReferralBonus = async (dispatch) => {
  let responseJson = await ApiEngine.get(
    ApiUrl._API_GET_UNCLAIM_REFERRAL_BONUS
  );
  if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
    dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
    throw responseJson[ApiKey._API_MESSAGE_KEY];
  }

  let data = responseJson[ApiKey._API_DATA_KEY];

  dispatch({
    type: _SET_UNCLAIM_REFERRAL_BONUS,
    data: data,
  });
};

export const bindGames = (games) => {
  return (dispatch) => {
    dispatch({
      type: _BIND_GAMES,
      games,
    });
  };
};

export const setThemeName = (themeName) => {
  return (dispatch) => {
    dispatch({
      type: _SET_THEME_NAME,
      themeName,
    });
  };
};

export const setPaymentList = (payments) => {
  return (dispatch) => {
    dispatch({
      type: _SET_PAYMENT_LIST,
      payments,
    });
  };
};

export const setPageSize = (pagesize) => {
  return (dispatch) => {
    dispatch({
      type: _SET_PAGESIZE,
      data: pagesize,
    });
  };
};

export const setActiveTab = (activeTab) => {
  return (dispatch) => {
    dispatch({
      type: _SET_ACTIVE_TAB,
      data: activeTab,
    });
  };
};

export const userKeepLogin = (isKeepedLogin) => {
  return (dispatch) => {
    dispatch({
      type: _IS_KEEPED_LOGIN,
      data: isKeepedLogin,
    });
  };
};

export const setDownlineSearchText = (e) => {
  return async (dispatch) => {
    dispatch({
      type: _DOWNLINE_SEARCH,
      data: e,
    });
  };
};

export const setDownlineQuery = (e) => {
  return async (dispatch) => {
    dispatch({
      type: _DOWNLINE_QUERY,
      data: e,
    });
  };
};

export const setReferralSearchText = (e) => {
  return async (dispatch) => {
    dispatch({
      type: _REFERRAL_SEARCH,
      data: e,
    });
  };
};

export const setReferralQuery = (e) => {
  return async (dispatch) => {
    dispatch({
      type: _REFERRAL_QUERY,
      data: e,
    });
  };
};

export const setGameSearchText = (e) => {
  return async (dispatch) => {
    dispatch({
      type: _GAME_SEARCH,
      data: e,
    });
  };
};

export const setGameQuery = (e) => {
  return async (dispatch) => {
    dispatch({
      type: _GAME_QUERY,
      data: e,
    });
  };
};

export const setWalletBalance = async (dispatch) => {
  let member = [];
  let balance = 0;

  let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);
  if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
    throw responseJson[ApiKey._API_MESSAGE_KEY];
  }

  member = responseJson[ApiKey._API_DATA_KEY];
  balance = isNaN(parseFloat(member["primaryCredit"]), 3)
    ? "0.00"
    : numberWithCurrencyFormat(parseFloat(member["primaryCredit"]), 2, true);

  dispatch({
    type: _SET_WALLET_BALANCE,
    data: balance,
  });
};
export const setWalletInfo = (e) => {
  return async (dispatch) => {
    dispatch({
      type: _SET_WALLET_INFO,
      data: e,
    });
  };
};

export const setOpenMenu = (openMenu) => {
  return async (dispatch) => {
    dispatch({
      type: _SET_OPEN_MENU,
      data: openMenu,
    });
  };
};

export const setReportOverview = ({
  displayData,
  newDisplayData,
  totalTransferToWallet,
  totalTransferToWalletLM,
  totalTransferToMember,
  totalTransferToMemberLM,
  totalBetAmount,
  totalBetAmountLM,
  totalCommission,
  totalCommissionLM,
  totalVipCommission,
  totalBonus,
}) => {
  return async (dispatch) => {
    dispatch({
      type: _SET_REPORT_OVERVIEW,
      displayData,
      newDisplayData,
      totalTransferToWallet,
      totalTransferToWalletLM,
      totalTransferToMember,
      totalTransferToMemberLM,
      totalBetAmount,
      totalBetAmountLM,
      totalCommission,
      totalCommissionLM,
      totalVipCommission,
      totalBonus,
    });
  };
};

export const setDownline = ({ memberData, recentData }) => {
  return async (dispatch) => {
    dispatch({
      type: _SET_DOWNLINE,
      memberData,
      recentData,
    });
  };
};
/// <summary>
/// Author :
/// </summary>
export const setBusy = (iconEnabled, message) => {
  return async (dispatch) => {
    dispatch({
      type: _BUSY,
      message: message,
      iconEnabled: iconEnabled,
    });
  };
};

/// <summary>
/// Author :
/// this function is to only update user status to logout
/// </summary>
export const setIdle = () => {
  return async (dispatch) => {
    dispatch({
      type: _IDLE,
    });
  };
};

/// <summary>
/// Author :
/// </summary>
export const setPerformApiCall = (status, url) => {
  return async (dispatch) => {
    dispatch({
      type: _API_CALLED,
      data: { success: status, url: url },
    });
  };
};

/// <summary>
/// Author: -
/// </summary>
export const showResponseMessage = (
  status,
  message,
  translateRequired = true
) => {
  return (dispatch) => {
    var messageToShow = message;
    if (translateRequired) {
      messageToShow = i18n.t(message);
    }
    dispatch(
      showMessage({
        type: status ? AlertTypes._SUCCESS : AlertTypes._ERROR,
        content: messageToShow,
      })
    );
  };
};

/// <summary>
/// Author: -
/// </summary>
export const showResponseMessageWithoutT = (
  status,
  message,
  translateRequired = true
) => {
  return (dispatch) => {
    var messageToShow = message;
    dispatch(
      showMessage({
        type: status ? AlertTypes._SUCCESS : AlertTypes._ERROR,
        content: messageToShow,
      })
    );
  };
};

/// <summary>
/// Author: -
/// </summary>
export const showMessage = (alertProps) => {
  return (dispatch) => {
    const customConfirm = alertProps.onConfirm;
    alertProps.onConfirm = async (...args) => {
      disposeMessage()(await dispatch);
      if (customConfirm) {
        customConfirm(...args);
      }
    };

    const customCancel = alertProps.onCancel;
    alertProps.onCancel = async (...args) => {
      disposeMessage()(await dispatch);
      if (customCancel) {
        customCancel(...args);
      }
    };

    alertProps.cancelBtnBsStyle = "danger";
    alertProps.title = alertProps.title ?? "";
    alertProps.allowEscape = false;
    alertProps.reverseButtons = true;

    dispatch({ type: _ALERT_TYPES.SET_ALERT, alert: alertProps });
  };
};

/// <summary>
/// Author: -
/// </summary>
export const disposeMessage = () => {
  return (dispatch) => {
    dispatch({ type: _ALERT_TYPES.NONE });
  };
};

/// <summary>
/// Author: -
/// </summary>
export const setDepositModal = (value, tab = 1) => {
  return (dispatch) => {
    dispatch({ type: _SET_DEPOSIT_MODAL, depositModal:value, depositTab:tab});
  };
};


/// <summary>
/// Author: -
/// </summary>
export const setDepositTab = (tab) => {
  return (dispatch) => {
    dispatch({ type: _SET_DEPOSIT_TAB, depositTab:tab});
  };
};

/// <summary>
/// Author: -
/// </summary>
export const setLoginModal = (value) => {
  return (dispatch) => {
    dispatch({ type: _SET_LOGIN_MODAL, loginModal:value});
  };
};

export const setRegisterModal = (value) => {
  return (dispatch) => {
    dispatch({ type: _SET_REGISTER_MODAL, registerModal:value});
  };
};