import {
  _IDLE,
  _BUSY,
  _ALERT_TYPES,
  _API_CALLED,
  _DOWNLINE_SEARCH,
  _DOWNLINE_QUERY,
  _REFERRAL_SEARCH,
  _REFERRAL_QUERY,
  _SET_Y,
  _SET_PAGESIZE,
  _SET_ACTIVE_TAB,
  _IS_KEEPED_LOGIN,
  _GAME_SEARCH,
  _GAME_QUERY,
  _SET_BOTTOM_TAB_INDEX,
  _BIND_MEMBER,
  _BIND_GAMES,
  _SET_PAYMENT_LIST,
  _SET_WALLET_BALANCE,
  _SET_WALLET_INFO,
  _SET_OPEN_MENU,
  _SET_REPORT_OVERVIEW,
  _SET_DOWNLINE,
  _SET_BANNERS,
  _SET_UNCLAIM_COMMISSION,
  _SET_UNCLAIM_REFERRAL_BONUS,
  _SET_THEME_NAME,
  _SET_DEPOSIT_MODAL,
  _SET_DEPOSIT_TAB,
  _SET_LOGIN_MODAL,
  _SET_REGISTER_MODAL,

} from "./AppAction";
import { stringIsNullOrEmpty } from "../util/Util";
import i18n from "../localization/i18n";

const _INITIAL_STATE = {
  isBusy: false,
  isKeepedLogin: false,
  message: i18n.t("LOADING"),
  alert: {},
  iconEnabled: true,
  apiCalledResponse: {},
  savedText: "",
  savedReferralText: "",
  gameSavedText: "",
  savedQuery: "/Member/RetrieveUserMemberList?directUserOnly=true",
  savedReferralQuery: "/Member/GetMemberReferralGroupsList",
  gameQuery: "/Game/GetMasterProductGroupedGame",
  y: 0,
  pageSize: 10,
  activeTab: 0,
  bottomTabIndex: 0,
  member: [],
  games: [],
  payments: [],
  walletBalance: 0,
  walletInfo: [],
  reportOverview: {
    displayData: [],
    newDisplayData: [],
    totalTransferToWallet: 0,
    totalTransferToWalletLM: 0,
    totalTransferToMember: 0,
    totalTransferToMemberLM: 0,
    totalBetAmount: 0,
    totalBetAmountLM: 0,
    totalCommission: 0,
    totalCommissionLM: 0,
    totalVipCommission: 0,
    totalBonus: 0,
  },
  downline: {
    memberData: null,
    recentData: null,
  },
  unclaimComm: 0,
  unclaimReferralBonus: 0,
  banners: [],
  themeName: "template1",
  openMenu: false,
  depositModal:false,
  depositTab:1,
  loginModal:false,
  registerModal:false
};

var _busyCount = 0;

export default (state = _INITIAL_STATE, action) => {
  switch (action.type) {
    case _BUSY:
      _busyCount++;
      return {
        ...state,
        isBusy: true,
        message: stringIsNullOrEmpty(action.message)
          ? i18n.t("LOADING")
          : action.message,
        iconEnabled:
          action.iconEnabled == null
            ? _INITIAL_STATE.iconEnabled
            : action.iconEnabled,
      };
      break;
    case _IDLE:
      _busyCount = Math.max(--_busyCount, 0);
      return {
        ...state,
        isBusy: _busyCount != 0,
      };
      break;
    case _ALERT_TYPES.SET_ALERT:
      return {
        ...state,
        alert: Object.keys(state.alert) == 0 ? action.alert : state.alert,
      };
      break;
    case _ALERT_TYPES.NONE:
      return {
        ...state,
        alert: {},
      };
      break;
    case _API_CALLED:
      return {
        ...state,
        apiCalledResponse: action.data,
      };
      break;
    case _DOWNLINE_SEARCH:
      return {
        ...state,
        savedText: action.data,
      };
      break;
    case _DOWNLINE_QUERY:
      return {
        ...state,
        savedQuery: action.data,
      };
      break;
    case _REFERRAL_SEARCH:
      return {
        ...state,
        savedReferralText: action.data,
      };
      break;
    case _REFERRAL_QUERY:
      return {
        ...state,
        savedReferralQuery: action.data,
      };
      break;
    case _GAME_SEARCH:
      return {
        ...state,
        gameSavedText: action.data,
      };
      break;
    case _GAME_QUERY:
      return {
        ...state,
        gameQuery: action.data,
      };
      break;
    case _SET_Y:
      return {
        ...state,
        y: action.y,
      };
    case _SET_PAGESIZE:
      return {
        ...state,
        pageSize: action.data,
      };
      break;
    case _SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.data,
      };
      break;
    case _IS_KEEPED_LOGIN:
      return {
        ...state,
        isKeepedLogin: action.data,
      };
      break;
    case _SET_BOTTOM_TAB_INDEX:
      return {
        ...state,
        bottomTabIndex: action.bottomTabIndex,
      };
      break;
    case _BIND_MEMBER:
      return {
        ...state,
        member: action.member,
        walletBalance: action.walletBalance,
      };
      break;
    case _BIND_GAMES:
      return {
        ...state,
        games: action.games,
      };
      break;
    case _SET_THEME_NAME:
      return {
        ...state,
        themeName: action.themeName,
      };
      break;
    case _SET_UNCLAIM_COMMISSION:
      return {
        ...state,
        unclaimComm: action.data,
      };
      break;
    case _SET_UNCLAIM_REFERRAL_BONUS:
      return {
        ...state,
        unclaimReferralBonus: action.data,
      };
      break;
    case _SET_PAYMENT_LIST:
      return {
        ...state,
        payments: action.payments,
      };
      break;
    case _SET_WALLET_BALANCE:
      return {
        ...state,
        walletBalance: action.data,
      };
      break;
    case _SET_OPEN_MENU:
      return {
        ...state,
        openMenu: action.data,
      };
      break;
    case _SET_WALLET_INFO:
      return {
        ...state,
        walletInfo: action.data,
      };
      break;

    case _SET_REPORT_OVERVIEW:
      state.reportOverview.displayData = action.displayData;
      state.reportOverview.newDisplayData = action.newDisplayData;

      state.reportOverview.totalTransferToWallet = action.totalTransferToWallet;
      state.reportOverview.totalTransferToWalletLM =
        action.totalTransferToWalletLM;
      state.reportOverview.totalTransferToMember = action.totalTransferToMember;
      state.reportOverview.totalTransferToMemberLM =
        action.totalTransferToMemberLM;
      state.reportOverview.totalBetAmount = action.totalBetAmount;
      state.reportOverview.totalBetAmountLM = action.totalBetAmountLM;
      state.reportOverview.totalCommission = action.totalCommission;
      state.reportOverview.totalCommissionLM = action.totalCommissionLM;
      state.reportOverview.totalVipCommission = action.totalVipCommission;
      state.reportOverview.totalBonus = action.totalBonus;

      return {
        ...state,
      };
      break;
    case _SET_DOWNLINE:
      state.downline.memberData = action.memberData;
      state.downline.recentData = action.recentData;
      return {
        ...state,
      };
      break;
    case _SET_BANNERS:
      return {
        ...state,
        banners: action.banners,
      };
      break;
    case _SET_DEPOSIT_MODAL:
      return {
        ...state,
        depositModal: action.depositModal,
        depositTab: action.depositTab
      };
      break;
    case _SET_DEPOSIT_TAB:
      return {
        ...state,
        depositTab: action.depositTab
      };
      break;

    case _SET_LOGIN_MODAL:
      return {
        ...state,
        loginModal: action.loginModal
      };
      break;

    case _SET_REGISTER_MODAL:
      return {
        ...state,
        registerModal: action.registerModal
      };
      break;

    default:
      return state;
      break;
  }
};
